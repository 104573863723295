import React, { Component } from 'react';
// import { Trans } from 'react-i18next';
import LiveChat from './LiveChat';
class Footer extends Component {
  render () {
    return (
      <footer className="footer">
      <LiveChat />
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-center text-sm-left d-block d-sm-inline-block">COPYRIGHT © 2024 <a href="/" target="_blank" rel="noopener noreferrer">mypayguru.com</a>, All Rights Reserved.</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;