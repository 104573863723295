import React, { Component } from 'react'

export class Spinner extends Component {
  render() {
    return (
      <div>
        <div class="fallback-spinner d-flex v-center">
          <div class="spinner-border-lg spinner-border">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    )
  }
}
export default Spinner