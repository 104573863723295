
export const getUserData = () => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : null;
};
export const getMasterRole = () => {
    return '64b63755c71461c502ea4715';
};
export const getSuperAdminRole = () => {
    return '64b63755c71461c502ea4713';
};