import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import axios from 'axios';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import './assets/css/font-awesome.min.css';
import './assets/css/line-awesome.min.css';
import './assets/css/main.css';
import dotenv from 'dotenv';

dotenv.config();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
(function() {
     const token = JSON.parse(localStorage.getItem('token'));
     if (token) {
         axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
     } else {
         axios.defaults.headers.common['Authorization'] = null;
         /*if setting null does not remove `Authorization` header then try
           delete axios.defaults.headers.common['Authorization'];
         */
     }
})();
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
, document.getElementById('root'));

serviceWorker.unregister();