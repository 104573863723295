import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Collapse, Dropdown} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {getMasterRole, getSuperAdminRole, getUserData} from '../userUtils';

class Sidebar extends Component {

    toggleOffmenu() {
        document.querySelector('body').classList.toggle('sidebar-hover');
        document.querySelector('.arrow-togg').classList.toggle('active');
    }

    state = {};

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({[menuState]: false});
        } else if (Object.keys(this.state).length === 0) {
            this.setState({[menuState]: true});
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({[i]: false});
            });
            this.setState({[menuState]: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        document.querySelector('#sidebar').classList.remove('active');
        Object.keys(this.state).forEach(i => {
            this.setState({[i]: false});
        });

        const dropdownPaths = [
            {path: '/apps', state: 'appsMenuOpen'},
            {path: '/basic-ui', state: 'basicUiMenuOpen'},
            {path: '/form-elements', state: 'formElementsMenuOpen'},
            {path: '/tables', state: 'tablesMenuOpen'},
            {path: '/icons', state: 'iconsMenuOpen'},
            {path: '/charts', state: 'chartsMenuOpen'},
            {path: '/user-pages', state: 'userPagesMenuOpen'},
            {path: '/error-pages', state: 'errorPagesMenuOpen'},
        ];

        dropdownPaths.forEach((obj => {
            if (this.isPathActive(obj.path)) {
                this.setState({[obj.state]: true})
            }
        }));

    }

    render() {
        const userData = getUserData();
        const masterRole = getMasterRole();
        const superAdminRole = getSuperAdminRole();

        if (window.innerWidth >= 991) {
            document.body.classList.add('sidebar-icon-only');
        }

        return (
            <nav className="sidebar sidebar-offcanvas sidebar-fixed" id="sidebar">
                <div
                    className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <a className="sidebar-brand brand-logo" href="index.html"><img
                        src={require('../../assets/images/sitelogo.png')} alt="logo"/></a>
                    <a className="sidebar-brand brand-logo-mini" href="index.html"><img
                        src={require('../../assets/images/logo_icon.png')} alt="logo"/></a>

                    <div className="toggler-btnbox">
                        <button className="navbar-toggler align-self-center" type="button" onClick={this.toggleOffmenu}>
                            <i class="arrow-togg las la-angle-double-right"></i>
                        </button>
                    </div>
                </div>
                <div className='scrollbar-container main-menu-content'>
                    <ul className="nav">
                        <li className={this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <Link className="nav-link" to="/dashboard">
                                <span className="menu-icon"><i class="las la-home"></i></span>
                                <span className="menu-title"><Trans>Dashboard</Trans></span>
                            </Link>
                        </li>
                        {/* <li className={this.isPathActive('/basic-ui') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                                onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                                <span className="menu-icon"><i class="las la-file"></i></span>
                                <span className="menu-title"><Trans>Masters</Trans></span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.basicUiMenuOpen}>
                                <div>
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/account') ? 'nav-link active' : 'nav-link'}
                                            to="/"><Trans>Account</Trans></Link></li>
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/item') ? 'nav-link active' : 'nav-link'}
                                            to="/"><Trans>Item</Trans></Link></li>
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/unit') ? 'nav-link active' : 'nav-link'}
                                            to="/"><Trans>Unit</Trans></Link></li>
                                    </ul>
                                </div>
                            </Collapse>
                        </li> */}
                        {(superAdminRole === userData.role) ?
                            <li className={this.isPathActive('/user') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                                <div className={this.state.userUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                                     onClick={() => this.toggleMenuState('userUiMenuOpen')} data-toggle="collapse">
                                    <span className="menu-icon"><i class="las la-user-alt"></i></span>
                                    <span className="menu-title"><Trans>User</Trans></span>
                                    <i className="menu-arrow"></i>
                                </div>
                                <Collapse in={this.state.userUiMenuOpen}>
                                    <div>
                                        <ul className="nav flex-column sub-menu">
                                            <li className="nav-item"><Link
                                                className={this.isPathActive('/user') ? 'nav-link active' : 'nav-link'}
                                                to="/user"><Trans>All Users</Trans></Link></li>
                                            <li className="nav-item"><Link
                                                className={this.isPathActive('/create-user') ? 'nav-link active' : 'nav-link'}
                                                to="/create-user"><Trans>Create User</Trans></Link></li>
                                        </ul>
                                    </div>
                                </Collapse>
                            </li>
                            : ''}
                        {(superAdminRole === userData.role) ?
                            <li className={this.isPathActive('/scheme') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                                <div className={this.state.schemeUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                                     onClick={() => this.toggleMenuState('schemeUiMenuOpen')} data-toggle="collapse">
                                    <span className="menu-icon"><i class="las la-network-wired"></i></span>
                                    <span className="menu-title"><Trans>Scheme</Trans></span>
                                    <i className="menu-arrow"></i>
                                </div>
                                <Collapse in={this.state.schemeUiMenuOpen}>
                                    <div>
                                        <ul className="nav flex-column sub-menu">
                                            <li className="nav-item"><Link
                                                className={this.isPathActive('/scheme') ? 'nav-link active' : 'nav-link'}
                                                to="/scheme"><Trans>Scheme Master</Trans></Link></li>
                                            <li className="nav-item"><Link
                                                className={this.isPathActive('/create-scheme') ? 'nav-link active' : 'nav-link'}
                                                to="/create-scheme"><Trans>Create Scheme</Trans></Link></li>
                                        </ul>
                                    </div>
                                </Collapse>
                            </li>
                            : ''}
                        {/* <li className={this.isPathActive('/sales') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <div className={this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                                onClick={() => this.toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
                                <span className="menu-icon"><i class="las la-book-open"></i></span>
                                <span className="menu-title"><Trans>Sales</Trans></span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.formElementsMenuOpen}>
                                <div>
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/quotation') ? 'nav-link active' : 'nav-link'}
                                            to="/"><Trans>Quotation</Trans></Link></li>
                                    </ul>
                                </div>
                            </Collapse>
                        </li> */}
                        {/* <li className={this.isPathActive('/purchase') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <Link className="nav-link" to="/purchase">
                                <span className="menu-icon"><i class="las la-shopping-bag"></i></span>
                                <span className="menu-title"><Trans>Purchase</Trans></span>
                            </Link>
                        </li>
                        <li className={this.isPathActive('/expenses') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <Link className="nav-link" to="/expenses">
                                <span className="menu-icon"><i class="las la-clipboard"></i></span>
                                <span className="menu-title"><Trans>Expenses</Trans></span>
                            </Link>
                        </li>
                        <li className={this.isPathActive('/receipt') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <Link className="nav-link" to="/receipt">
                                <span className="menu-icon"><i class="las la-tasks"></i></span>
                                <span className="menu-title"><Trans>Receipt</Trans></span>
                            </Link>
                        </li> */}
                        {/*{(masterRole === userData.role) ?
                            <li className={this.isPathActive('/order') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                                <Link className="nav-link" to="/order">
                                    <span className="menu-icon"><i class="las la-credit-card"></i></span>
                                    <span className="menu-title"><Trans>Payment</Trans></span>
                                </Link>
                            </li>
                            : ''}*/}
                        {/* <li className={this.isPathActive('/journal') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <div className={this.state.journalElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                                onClick={() => this.toggleMenuState('journalElementsMenuOpen')} data-toggle="collapse">
                                <span className="menu-icon"><i class="las la-clipboard-list"></i></span>
                                <span className="menu-title"><Trans>Journal</Trans></span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.journalElementsMenuOpen}>
                                <div>
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/journal-voucher') ? 'nav-link active' : 'nav-link'}
                                            to="/"><Trans>Journal Voucher</Trans></Link></li>
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/stock-journal') ? 'nav-link active' : 'nav-link'}
                                            to="/"><Trans>Stock Journal</Trans></Link></li>
                                    </ul>
                                </div>
                            </Collapse>
                        </li> */}
                        <li className={this.isPathActive('/report') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                            <div className={this.state.repotrsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                                 onClick={() => this.toggleMenuState('repotrsMenuOpen')} data-toggle="collapse">
                                <span className="menu-icon"><i class="las la-chart-pie"></i></span>
                                <span className="menu-title"><Trans>Reports</Trans></span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.repotrsMenuOpen}>
                                <div>
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/payin-report') ? 'nav-link active' : 'nav-link'}
                                            to="/payin-report"><Trans>Pay In Report</Trans></Link></li>
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/payout-report') ? 'nav-link active' : 'nav-link'}
                                            to="/payout-report"><Trans>Pay Out Report</Trans></Link></li>
                                        <li className="nav-item"><Link
                                            className={this.isPathActive('/ledger-report') ? 'nav-link active' : 'nav-link'}
                                            to="/ledger-report"><Trans>Ledger Report</Trans></Link></li>
                                    </ul>
                                </div>
                            </Collapse>
                        </li>
                        {(superAdminRole === userData.role) ?
                            <li className={this.isPathActive('/bank') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                                <Link className="nav-link" to="/bank">
                                    <span className="menu-icon"><i class="las la-university"></i></span>
                                    <span className="menu-title"><Trans>Bank</Trans></span>
                                </Link>
                            </li>
                            : ''}
                    </ul>
                    {/* <div className='live_chat_block'>
                        <ul className="nav">
                            <li className={this.isPathActive('/livechat') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                                <Link className="nav-link live_chat_link" to="/">
                                    <span className="menu-icon chat_icon d-flex"><img
                                        src={require('../../assets/images/chat_icon.svg')} alt="" /></span>
                                    <span className="menu-title"><Trans>Live chat</Trans></span>
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </nav>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });


        //Sidebar Hover Script
        if (window.innerWidth >= 991) {
            document.querySelectorAll('#sidebar').forEach((eb) => {
                eb.addEventListener('mouseover', function () {
                    if (body.classList.contains('sidebar-icon-only')) {
                        document.querySelector('body').classList.remove('sidebar-icon-only');
                        document.querySelector('body').classList.add('sidebar-hover');
                    }
                });
                eb.addEventListener('mouseout', function () {
                    if (body.classList.contains('sidebar-hover')) {
                        document.querySelector('body').classList.add('sidebar-icon-only');
                    }
                    document.querySelector('body').classList.remove('sidebar-hover');
                });
            });
        }

    }

}

export default withRouter(Sidebar);