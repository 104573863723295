import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LiveChat = () => {
  // useEffect(() => {
  //   const tawkScript = document.createElement('script');
  //   tawkScript.src = 'https://embed.tawk.to/65e199419131ed19d97380d5/1hnsjtau9';
  //   tawkScript.async = true;
  //   document.head.appendChild(tawkScript);

  //   return () => {
  //     document.head.removeChild(tawkScript);
  //   };
  // }, []);

  return (
    <div>
    {/* <Helmet>
      <script type="text/javascript">{`var Tawk_API=Tawk_API||{}; Tawk_API.visitor={}; Tawk_API.visitor.name=''; Tawk_API.visitor.email='';`}</script>
    </Helmet> */}
      <div class="bottom-fix-whatsapp">
        <a href="https://api.whatsapp.com/send?phone=19497888888" target="_blank" title="whatsapp"><i class="lab la-whatsapp"></i></a>
      </div>
    </div>
  );
};

export default LiveChat;