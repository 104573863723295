// AuthMiddleware.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect , useLocation} from 'react-router-dom';

const AuthMiddleware = ({ children }) => {
  const location = useLocation();
  const { pathname, search } = location;
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if(pathname === '/') {
    return <Redirect to="/dashboard" />;
  }
  return children;
};

export default AuthMiddleware;
